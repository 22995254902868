import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ metaData, lang }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDataInformation = metaData
        const title = metaDataInformation.title || data.site.siteMetadata.title
        const metaKeyWords = metaDataInformation.keywords || data.site.siteMetadata.keywords;
        const imageSrc = Object.keys(metaDataInformation).length === 0 ? data.site.siteMetadata.image : ( metaDataInformation.image !== null ? metaDataInformation.image.publicURL : data.site.siteMetadata.image )
        const twiterImageSrc = Object.keys(metaDataInformation).length === 0 ? data.site.siteMetadata.twiterImage : ( metaDataInformation.twiterImage !== null ? metaDataInformation.twiterImage.publicURL : data.site.siteMetadata.twiterImage )
        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={{ title }}
            titleTemplate={`${title}`}
            meta={
              [
                {
                  name: `robots`,
                  content: `index, follow`
                },
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  name: `description`,
                  content: metaDataInformation.description || data.site.siteMetadata.description
                },
                {
                  name: `title`,
                  content: title
                },
                {
                  name: `author`,
                  content: `Portare Group SAS`
                },
                // Google and Facebook
                {
                  property: `og:type`,
                  content: `website`
                },
                {
                  property: `og:title`,
                  content: title
                },
                {
                  property: `og:description`,
                  content: metaDataInformation.description || data.site.siteMetadata.description
                },
                {
                  property: `og:site_name`,
                  content: title
                },
                {
                  property: `og:url`,
                  content: metaDataInformation.siteUrl || data.site.siteMetadata.url
                },
                {
                  property: `fb:app_id`,
                  content: `217979279383814`
                },
                {
                  property: `og:image`,
                  content: imageSrc
                },
                {
                  property: `og:image:alt`,
                  content: metaDataInformation.imageAlt || data.site.siteMetadata.imageAlt
                },
                // Twiter
                {
                  name: `twitter:card`,
                  content: `summary`
                },
                {
                  name: `twitter:title`,
                  content: title
                },
                {
                  name: `twitter:description`,
                  content: metaDataInformation.description || data.site.siteMetadata.description
                },
                {
                  name: `twitter:site`,
                  content: metaDataInformation.siteUrl || data.site.siteMetadata.url
                },
                {
                  name: `twitter:creator`,
                  content: metaDataInformation.twiterProfile || data.site.siteMetadata.twiterProfile
                },
                {
                  name: `twitter:image:src`,
                  content: twiterImageSrc
                }
              ]
              .concat( metaKeyWords.length > 0 ? { name: `keywords`, content: metaKeyWords.join(`, `), } : [] )
            }
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `es`,
  metaData: {},
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        imageAlt
        twitterProfile
        twiterImage
        keywords
      }
    }
  }
`;
